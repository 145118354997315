var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-start align-center flex-column pa-0",attrs:{"fluid":"","fill-height":""}},[_c('v-overlay',{attrs:{"value":_vm.processing}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('migration-logo')],1)],1),_c('v-row',{staticClass:"mt-1 mb-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.subTitle))])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-1 pa-5 mb-3 rounded-xl ",class:_vm.$style.form,attrs:{"color":"#fff","rounded":""}},[_c('v-card-text',{class:_vm.$style.form_text},[_c('h2',{class:_vm.$style.form_msg},[_vm._v(_vm._s(_vm.jreid_regis_title))]),_c('base-btn',{style:({
    color: 'white',
    fontSize: '1rem',
    boxShadow: 'none'
  }),attrs:{"color":'#72AD47',"label":_vm.jreid_regis_button},on:{"click":function($event){return _vm.migration_start(false)}}}),_c('div',{staticClass:"py-3"}),_c('h2',{class:_vm.$style.form_msg},[_vm._v(_vm._s(_vm.jreid_login_title))]),_c('base-btn',{style:({
    color: 'white',
    fontSize: '1rem',
    boxShadow: 'none'
  }),attrs:{"color":'#72AD47',"label":_vm.jreid_login_button},on:{"click":function($event){return _vm.migration_start(true)}}}),_c('div',{staticClass:"pb-8"}),_c('h2',{class:_vm.$style.form_msg,staticStyle:{"margin-bottom":"20px"}},[_vm._v("JaM idマイページは"),_c('a',{attrs:{"href":"/mypage"}},[_vm._v("こちら")])]),_c('h2',{class:_vm.$style.form_msg,style:({ margin: '10px -25px' })},[_vm._v(" 「JRE ID新規会員登録」から切替手続きした場合"),_c('br'),_vm._v(" JaM idで利用中のメールアドレスは"),_c('br'),_vm._v(" JRE IDのログインメールアドレスに"),_c('br'),_vm._v(" 引き継がれます。 ")]),_c('h2',{class:_vm.$style.form_msg,style:({ margin: '0 -27px' })},[_vm._v(" JaM idとは別のメールアドレスで"),_c('br'),_vm._v(" JRE IDをご利用する場合は、"),_c('br'),_vm._v(" 各サービスサイトの「新規会員登録」から"),_c('br'),_vm._v(" JRE IDの新規会員登録を行ってください。"),_c('br'),_vm._v(" ※このページの「JRE ID 新規会員登録」からでは"),_c('br'),_vm._v(" JaM idのメールアドレスが引き継がれます。 ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }