<template>
  <v-container fluid fill-height class="d-flex justify-start align-center flex-column pa-0">
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <migration-logo />
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-0">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ subTitle }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-card color="#fff" class="py-1 pa-5 mb-3 rounded-xl " rounded :class="$style.form">
            <v-card-text :class="$style.form_text">
              <h2 :class="$style.form_msg">{{ jreid_regis_title }}</h2>
              <base-btn :color="'#72AD47'" :label="jreid_regis_button" @click="migration_start(false)" :style="{
      color: 'white',
      fontSize: '1rem',
      boxShadow: 'none'
    }" />
              <div class="py-3"></div>
              <h2 :class="$style.form_msg">{{ jreid_login_title }}</h2>
              <base-btn :color="'#72AD47'" :label="jreid_login_button" @click="migration_start(true)" :style="{
      color: 'white',
      fontSize: '1rem',
      boxShadow: 'none'
    }" />
              <div class="pb-8">
              </div>
              <h2 :class="$style.form_msg" style="margin-bottom: 20px;">JaM idマイページは<a href="/mypage">こちら</a></h2>
              <h2 :class="$style.form_msg" :style="{ margin: '10px -25px' }">
                <!-- ※「JRE ID新規会員登録」から移行手続きを開始すると、JaM idで利用しているメールアドレスが<br>JRE IDのログインメールアドレスに引き継がれます。 -->
                「JRE ID新規会員登録」から切替手続きした場合<br>
                  JaM idで利用中のメールアドレスは<br>
                  JRE IDのログインメールアドレスに<br>
                  引き継がれます。
              </h2>
              <h2 :class="$style.form_msg" :style="{ margin: '0 -27px' }">
                <!-- JRE IDをJaM idとは別のメールアドレスで利用されたい場合は、<br><a href="">JRE IDサイト</a>にてJRE IDを新規作成の上、「JRE
                IDログイン」から移行手続きを行ってください。 -->
                  JaM idとは別のメールアドレスで<br>
                  JRE IDをご利用する場合は、<br>
                  各サービスサイトの「新規会員登録」から<br>
                  JRE IDの新規会員登録を行ってください。<br>
                  ※このページの「JRE ID 新規会員登録」からでは<br>
                  JaM idのメールアドレスが引き継がれます。
              </h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { clientWithoutAuth } from "@/entries/front/axios";
import utility from "@/utility";

export default {
  data() {
    return {
      subTitle: 'アカウント切替画面',
      resourceName: "migration",
      firstHeader: 'このサービスをご利用いただ<br>くために',
      secondHeader: '「JRE ID」の登録が必要です。',
      jreid_regis_title: 'JRE IDをお持ちでない方はこちら',
      jreid_login_title: 'JRE IDをすでにお持ちの方はこちら',
      jreid_regis_button: 'JRE ID 新規会員登録',
      jreid_login_button: 'JRE ID ログイン',
      email_update_button: 'メールアドレス更新',
      emil_update_title: 'メールアドレス更新'
    };
  },
  computed: {
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/processing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    }
  },
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    // TODO: 
    migration_start(loginFlag) {
      // var data = { "jreIdLoginFlag": loginFlag }

      this.$store
        .dispatch(`${this.resourceName}/entity/migrationStart`, {
          jreIdLoginFlag: loginFlag
        })
        .then((response) => {
          this.$store.commit("form/clearDirty");
          console.log('Migration url: ', response.data.url);

          if (response.data.url) {
            console.log('response.data.url FOUND & redirecting...')
            window.location.href = response.data.url
          } else {
            console.log('response.data.url NOT FOUND:')
          }
        })
        .catch(() => { });
    }
  },
  created() {
    this.$store.commit(`${this.resourceName}/entity/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
    this.$store.dispatch(`${this.resourceName}/entity/getAccountInfo`);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
  }
}
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.second_title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 500;
}

.form_msg {
  font-size: 13px !important;
  color: #373e42;
  font-weight: 600;
  text-align: center !important;
  margin: 10px 0px;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.migration_start_btn {
  background-color: #72AD47 !important;
  border-radius: 4px !important;
  color: white !important;
  font-size: 1rem;
  box-shadow: none !important;
}

.back {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.back_icon {
  margin-top: 2px;
}
</style>
